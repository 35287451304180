import React, { useState} from "react"
import { Helmet } from "react-helmet"
import Button from 'src/components/button'
// import Checkbox from 'src/components/checkbox'
// import PageContext from 'src/components/page_context'
import styles from 'src/css/components/lead_sign_up.module.css'
import { withRecaptcha, postData } from 'src/utilities/request'
// import { countryIds } from "../utilities/locale"




const EmailStart = () => {
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const countryid = "nz"


  const handleClick = async () => {
    setLoading(true)
    const link = "https://signup.laybuy.com/"
    const url = new URL(link)
    try {
      if (email) {
        global.dataLayer.push({ 'event': 'lead_submitted', countryid, 'subscribe': 'yes' })
        const response = await subscribe({ email, countryid, 'subscribe': 'yes' })
        const { token } = await response.json()
        url.searchParams.set('lead_token', encodeURIComponent(token))
      }
    } catch { }
    window.location.href = url;
  }

  return <div className={styles.signUp} style={{ }}>
    <Helmet bodyAttributes={{ class: "landing-page-with-recaptcha" }}>
      <script async src={`https://www.google.com/recaptcha/api.js?render=${process.env.GATSBY_RECAPTCHA_SITE_KEY}`}></script>
    </Helmet>
    <input type="email" className={styles.emailInput} placeholder="Your Email Address" style={{ width: '280px' }} value={email} onChange={({ target: { value } }) => setEmail(value)} />
    <Button disabled={loading} onClick={handleClick} style={{ color: 'white', background: '#000' }}>Register now</Button>
  </div>
}

const subscribe = async (params) => {
  return await withRecaptcha(async function (recaptchaToken) {
    try {
      return await postData(
        `${process.env.GATSBY_PREFERENCE_CENTRE_API_URL}/campaigns/sign_up`,
        { data: { attributes: { ...params, recaptchaToken } } }
      )
    } catch { }
  })
}

export default EmailStart